import { State } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import { RootState } from "@store/types";

@Component
export default class Avatar extends Vue {
  @State
  private readonly enabledNewYearDesign!: RootState["enabledNewYearDesign"];

  @Prop({ type: String, default: "smp-28" })
  private readonly avatarName!: string;
  @Prop({ type: [String, Number], default: "40" })
  private readonly size!: string;
  @Prop({ type: String })
  private readonly skin?: "snowflake";

  private get avatarSrc() {
    return require(`./img/${this.avatarName}.png`);
  }

  private get hasListeners() {
    return Object.keys(this.$listeners).length > 0;
  }
}
